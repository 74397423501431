import React from 'react'

import { useApp } from '@/core/contexts/app'
import { useWidth } from '@/core/hooks/useWidthResize'

import { Column, Container, Row } from '@/components/base/gridview'
import Anchor from '@/components/base/anchor'
import Image from '@/components/base/image'
import IconList, { IconListItem } from '@/components/base/icon-list'

import Icon from '@/components/base/icon'
import { UlupinarFooterProps } from './type'
import Link from 'next/link'

const UlupinarFooter: React.FunctionComponent<UlupinarFooterProps> = ({
  ftrnavigation,
  mail,
}) => {
  const app = useApp()
  const width = useWidth()

  return (
    <footer className="ulupinar-footer">
      <div className="ulupinar-footer-top">
        <Container size="medium">
          <Row xs={{ justify: 'between', align: 'center' }}>
            <Column
              xs={{ size: 12 }}
              sm={{ size: 12 }}
              md={{ size: 12 }}
              lg={{ size: 4 }}
              xl={{ size: 4 }}
            >
              <div className="ulupinar-footer-content">
                <div className="ulupinar-footer-invite">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: app.settings.translations['ulupinar-invite'],
                    }}
                  ></div>
                  <a className="contactbtn" href={`mailto:${mail}`}>
                    {app.settings.translations['contact-us']}
                  </a>
                </div>
              </div>
            </Column>

            <Column
              xs={{ size: 12 }}
              sm={{ size: 12 }}
              md={{ size: 12 }}
              lg={{ size: 4 }}
              xl={{ size: 4 }}
            >
              <div className="ulupinar-footer-social-media">
                <div
                  className="ulupinar-footer-social-media-title"
                  dangerouslySetInnerHTML={{
                    __html: app.settings.translations['we-on-social'],
                  }}
                />
                <IconList className="ulupinar-footer-social-media-list">
                  <Link href={app.settings.socials['instagram']}>
                    {app.settings.socials['instagram'] && (
                      <Icon name="icon-instagram" />
                    )}
                  </Link>

                  <Link href={app.settings.socials['linkedin']}>
                    {app.settings.socials['linkedin'] && (
                      <Icon name="icon-linkedin2" />
                    )}
                  </Link>
                  <Link href={app.settings.socials['youtube']}>
                    {app.settings.socials['youtube'] && (
                      <Icon name="icon-youtube" />
                    )}
                  </Link>
                </IconList>
                <div className="go-top">
                  <button
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      })
                    }}
                  >
                    {app.settings.translations['go-top']}
                  </button>
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </div>

      <div className="ulupinar-footer-navigation-menu">
        <Container size="medium">
          <Row xs={{ justify: 'between' }}>
            <Column
              xs={{ size: 12 }}
              sm={{ size: 12 }}
              md={{ size: 6 }}
              lg={{ size: 6 }}
              xl={{ size: 6 }}
            >
              <ul>
                {ftrnavigation?.map((menuItem, index) => (
                  <li key={index}>
                    {menuItem.children.length ? (
                      <Anchor
                        dangerouslySetInnerHTML={{ __html: menuItem.title }}
                      />
                    ) : (
                      <Anchor
                        href={menuItem.href}
                        dangerouslySetInnerHTML={{ __html: menuItem.title }}
                      />
                    )}

                    {menuItem.children.length ? (
                      <ul className="sub-menu">
                        {menuItem.children.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            <Anchor href={subItem.href}>{subItem.title}</Anchor>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </li>
                ))}
              </ul>
            </Column>

            <Column
              xs={{ size: 12 }}
              sm={{ size: 12 }}
              md={{ size: 6 }}
              lg={{ size: 6 }}
              xl={{ size: 6 }}
            >
              <div className="ulupinar-footer-copyright">
                {app.settings.translations['copyright']}
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    </footer>
  )
}

export default UlupinarFooter
